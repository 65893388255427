import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { useQuery } from '@apollo/client';
import {
  tableIcons,
  gql_get_paid_offers_count,
  gql_get_total_offers_count,
  gql_get_paid_jobs_count,
  gql_get_total_jobs_count
} from './helpers';

const OffersCount = (partner) => {
  const { loading: paidOffersLoading, data: paidOffersData } = useQuery(gql_get_paid_offers_count, {
    variables: {
      partner: partner.id,
    },
  });

  const { loading: allOffersLoading, data: allOffersData } = useQuery(gql_get_total_offers_count, {
    variables: {
      partner: partner.id,
    },
  });

  if (allOffersData && paidOffersData && !paidOffersLoading && !allOffersLoading) {
    return { ...partner, paid: paidOffersData.getPaidOffersCount, total: allOffersData.getTotalOffersCount };
  }

  return partner;
};

const JobsCount = (partner) => {
  const { loading: paidJobsLoading, data: paidJobsData } = useQuery(gql_get_paid_jobs_count, {
    variables: {
      partner: partner.id,
    },
  });

  const { loading: allJobsLoading, data: allJobsData } = useQuery(gql_get_total_jobs_count, {
    variables: {
      partner: partner.id,
    },
  });

  if (allJobsData && paidJobsData && !paidJobsLoading && !allJobsLoading) {
    return { ...partner, paid: paidJobsData.getPaidJobsCount, total: allJobsData.getTotalJobsCount };
  }

  return partner;
};

export default ({ onRowClick, formattedData, role }) => {
  const [selectedPartnerRow, setSelectedPartnerRow] = useState(null);
  const [sorting, setSorting] = useState({ orderedColumnId: 2, orderDirection: 'desc' });

  const checkRoleForCount = (partner, role) => {
    if (role === 'jobs') {
      return JobsCount(partner);
    }
    if (role === 'offers') {
      return OffersCount(partner);
    }
  };

  return (
    <>
      {formattedData && (
        <MaterialTable
          style={{ padding: '1px', height: '100%' }}
          columns={[
            {
              title: 'Company', field: 'company',
              defaultSort: sorting.orderedColumnId === 0 ? sorting.orderDirection : null
            },
            {
              title: 'Email', field: 'email',
              defaultSort: sorting.orderedColumnId === 1 ? sorting.orderDirection : null
            },
            {
              field: 'paid',
              title: 'Paid',
              defaultSort: sorting.orderedColumnId === 2 ? sorting.orderDirection : null
            },
            {
              field: 'total', title: 'Total',
              defaultSort: sorting.orderedColumnId === 3 ? sorting.orderDirection : null
            }
          ]}
          data={formattedData.map((partner) => checkRoleForCount(partner, role))}
          title='Partners'
          icons={tableIcons}
          onRowClick={(event, rowData) => {
            setSelectedPartnerRow(rowData.tableData.id);
            onRowClick(event, rowData);
          }}
          onOrderChange={(orderedColumnId, orderDirection) => {
            setSorting(orderedColumnId, orderDirection);
          }}
          options={{
            padding: 'dense',
            sorting: true,
            rowStyle: (rowData) => ({
              fontSize: '0.8rem',
              backgroundColor:
                selectedPartnerRow === rowData.tableData.id ? '#EEE' : '#FFF',
            }),
          }}
        />
      )}
    </>
  );
};
