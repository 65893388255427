import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useQuery, useMutation} from '@apollo/client';
import {
  formatStatus,
  formatDates,
  capitalizeHeader,
  tableIcons,
  qql_get_jobs_by_partner,
  gql_update_job_status,
  JobStatus,
} from './helpers';
import {
  gql_get_paid_jobs_count,
} from '../PartnerList/helpers';
import {pipe} from '../../utils';
import {
  BsXCircleFill,
  BsCheckCircle,
  BsFillPlayFill,
  BsFillPauseFill,
} from 'react-icons/bs';

const Loading = () => <p>Loading</p>;

const Error = ({error}) => <pre>{JSON.stringify(error, null, 2)}</pre>;

export default ({onRowClick, setSelectedJobData, selectedPartner}) => {
  const [selectedJobRow, setSelectedJobRow] = useState(null);

  const formatJob = pipe(formatStatus, formatDates, capitalizeHeader);

  if (!selectedPartner.id) {
    return null;
  }

  const {loading, error, data} = useQuery(qql_get_jobs_by_partner, {
    variables: {partner: selectedPartner.id},
  });

  const formattedJobs = data?.getJobs.jobs.map((job) => {
    return formatJob(job);
  });

  const [updateJobStatus] = useMutation(gql_update_job_status, {
    refetchQueries: [
      {query: qql_get_jobs_by_partner},
      {
        query: gql_get_paid_jobs_count,
        variables: {
          partner: selectedPartner.id,
        },
      },
    ],
  });

  useEffect(() => {
    setSelectedJobRow(null);
  }, [selectedPartner.id]);

  const reject = rejectAction(updateJobStatus, setSelectedJobData);
  const approve = approveAction(updateJobStatus, setSelectedJobData);
  const publish = publishAction(updateJobStatus, setSelectedJobData);
  const unpublish = unpublishAction(updateJobStatus, setSelectedJobData);

  return (
    <>
      {error && <Error error={error} />}

      {loading && <Loading />}

      {formattedJobs && (
        <MaterialTable
          columns={[
            {title: 'Created', field: 'created', defaultSort: 'asc'},
            {title: 'Modified', field: 'modified'},
            {title: 'Title', field: 'title'},
            {title: 'Status', field: 'status_label'},
          ]}
          data={formattedJobs}
          title='Jobs'
          icons={tableIcons}
          onRowClick={(event, rowData) => {
            setSelectedJobRow(rowData.tableData.id);
            onRowClick(rowData);
          }}
          options={{
            actionsColumnIndex: -1,
            padding: 'dense',
            sorting: true,
            rowStyle: (rowData) => ({
              fontSize: '0.8rem',
              backgroundColor:
                selectedJobRow === rowData.tableData.id ? '#EEE' : '#FFF',
            }),
          }}
          actions={[
            reject,
            approve,
            publish,
            unpublish
          ]}
        />
      )}
    </>
  );
};

const rejectAction = (updateJobStatus, setSelectedJobData) => (rowData) => {
  const validStatuses = [JobStatus.APPROVED, JobStatus.UNPUBLISHED, JobStatus.PENDING, JobStatus.PAID];
  const isStatusValid = validStatuses.includes(rowData.status);

  return {
    icon: () => (
      <BsXCircleFill
        size='1.5rem'
        style={{color: isStatusValid ? 'red' : 'grey', margin: '0rem 0.3rem'}}
      />
    ),
    tooltip: 'Reject',
    onClick: () => {
      updateJobStatus({
        variables: {
          id: rowData.id,
          status: JobStatus.REJECTED,
        },
      });
      setSelectedJobData({
        ...rowData,
        status: JobStatus.REJECTED,
      });
    },
    disabled: !isStatusValid,
  };
};

const approveAction = (updateJobStatus, setSelectedJobData) => (rowData) => {
  const validStatuses = [JobStatus.PAID, JobStatus.REJECTED];
  const isStatusValid = validStatuses.includes(rowData.status);

  return {
    icon: () => (
      <BsCheckCircle
        size='1.5rem'
        style={{color: isStatusValid ? 'limegreen' : 'grey', margin: '0rem 0.3rem'}}
      />
    ),
    tooltip: 'Approve',
    onClick: () => {
      updateJobStatus({
        variables: {
          id: rowData.id,
          status: JobStatus.APPROVED,
        },
      });
      setSelectedJobData({
        ...rowData,
        status: JobStatus.APPROVED,
      });
    },
    disabled: !isStatusValid,
  };
};

const publishAction = (updateJobStatus, setSelectedJobData) => (rowData) => {
  const validStatuses = [JobStatus.APPROVED, JobStatus.UNPUBLISHED];
  const isStatusValid = validStatuses.includes(rowData.status);

  return {
    icon: () => (
      <BsFillPlayFill
        size='1.5rem'
        style={{color: isStatusValid ? 'limegreen' : 'grey'}}
      />
    ),
    tooltip: 'Clicking this button will publish the job',
    onClick: () => {
      updateJobStatus({
        variables: {
          id: rowData.id,
          status: JobStatus.PUBLISHED,
        },
      });
      setSelectedJobData({
        ...rowData,
        status: JobStatus.PUBLISHED,
      });
    },
    disabled: !isStatusValid,
  };
};

const unpublishAction = (updateJobStatus, setSelectedJobData) => (rowData) => {
  const validStatuses = [JobStatus.PUBLISHED];
  const isStatusValid = validStatuses.includes(rowData.status);

  return {
    icon: () => (
      <BsFillPauseFill
        size='1.5rem'
        style={{color: isStatusValid ? 'red' : 'grey'}}
      />
    ),
    tooltip: 'Clicking this button will unpublish this job',
    onClick: () => {
      updateJobStatus({
        variables: {
          id: rowData.id,
          status: JobStatus.UNPUBLISHED,
        },
      });
      setSelectedJobData({
        ...rowData,
        status: JobStatus.UNPUBLISHED,
      });
    },
    disabled: !isStatusValid,
  };
};

