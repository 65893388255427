import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import SubNavbar from '../SubNavbar/SubNavbar';
import Offers from './offers';
import Jobs from './jobs';
import {useQuery} from '@apollo/client';
import {
  gql_get_partners_for_list_component,
} from '../PartnerList/helpers';


export default () => {
  const [view, setView] = useState('offers');

  const {loading, error, data} = useQuery(
    gql_get_partners_for_list_component
  );

  const Loading = () => <p>Loading</p>;

  const Error = ({error}) => <pre>{JSON.stringify(error, null, 2)}</pre>;

  return (
    <>
      <SubNavbar view={view} setView={setView} />
      {error && <Error error={error} />}
      {loading && <Loading />}
      <Container fluid>
        {view === 'offers' && data && (
          <Offers partnerData={data} />
        )}
        {view === 'jobs' && data && (
          <Jobs partnerData={data} />
        )}
      </Container>
    </>
  );
};