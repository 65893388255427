import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import styles from './SubNavbar.module.scss';

const SubNavbar = ({ view, setView }) => {
  return (
    <Container fluid className={styles.subnav}>
      <Nav className={styles.link}>
        <Nav.Link
          style={{ fontWeight: view === 'offers' ? 700 : 400 }}
          onClick={() => setView('offers')}
        >
          Offers
        </Nav.Link>
        <Nav.Link
          style={{ fontWeight: view === 'jobs' ? 700 : 400 }}
          onClick={() => setView('jobs')}
        >
          Jobs
        </Nav.Link>
      </Nav>
    </Container>
  );
};

export default SubNavbar;
