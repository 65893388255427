import * as React from 'react';
import {Route} from 'react-router-dom';

// Viewcomponents
import Main from './components/Views/main';

const NotFound = () => <div>Not found</div>;

export default [
  <Route path='/' component={Main} key='root' />,
  <Route path='*' component={NotFound} key='not-found' />,
];
