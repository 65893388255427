import React, {useState, useEffect} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import {Auth} from '@aws-amplify/auth';
import {useRouteMatch, Link} from 'react-router-dom';
import {BsFillPersonFill} from 'react-icons/bs';
import Logo from '../img/frank-logo.svg';
import styles from './Navbar.module.scss';
import {LinkContainer} from 'react-router-bootstrap';

export default function Navigation() {
  const [userName, setUserName] = useState('guest');
  const shouldDisableNavbar = useRouteMatch(['/login', '/recover', '/register']);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await Auth.signOut();
    } catch (e) {
      alert('Unable to log out, please get in touch with customer support.');
    }
  };

  const getUserName = () => {
    Auth.currentUserInfo()
      .then((userInfo) => {
        const {attributes} = userInfo;
        setUserName(attributes['email']);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log('Navbar: ', error));
  };

  useEffect(() => {
    getUserName();
  }, [userName]);

  const navDropdownTitle = (
    <div style={{display: 'inline-block'}}>
      <BsFillPersonFill /> {userName}{' '}
    </div>
  );

  return shouldDisableNavbar ? null : (
    <Navbar bg='light' expand='lg' variant='light' className={styles.navbar}>
      <Navbar.Brand>
        <Link to='/'>
          <img src={Logo} alt='Frank Logo' className={styles.logo} />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav' className='justify-content-end'>
        <Nav>
          <NavDropdown title={navDropdownTitle} id='nav-dropdown'>
            <LinkContainer to='/' activeClassName=''>
              <NavDropdown.Item active={false} eventKey='logout' onClick={handleLogout}>
                Log out
              </NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
