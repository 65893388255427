import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './style/global.scss';
import { createHttpLink } from '@apollo/client/link/http';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import Amplify from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { createAppSyncLink } from 'aws-appsync';
import styles from './style/App.module.scss';
import Router from './router';
import awsconfig from './aws-exports';

awsconfig.aws_user_pools_id = 'eu-west-1_9ttNR9DKU';
awsconfig.aws_user_pools_web_client_id = '1j805vkfrv4loaib3k1oqh8hiu';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const awsLink = createAppSyncLink({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: awsconfig.aws_appsync_authenticationType,
    credentials: () => Auth.currentCredentials(),
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
});

const httpLink = createHttpLink({ uri: awsconfig.aws_appsync_graphqlEndpoint });

export const client = new ApolloClient({
  link: awsLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const AppShell = () => {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <ApolloProvider client={client}>
      <div className={styles.App}>
        <Router />
      </div>
    </ApolloProvider>
  ) : (
    <FPPAuth />
  );
};

const FPPAuth = () => {
  const url = new URL(document.location.href),
    path = url.pathname;

  return (
    <AmplifyAuthenticator usernameAlias='email' initialAuthState={path === '/signup' ? AuthState.SignUp : AuthState.SignIn}>
      <AmplifySignIn
        headerText='FPP Admin Signin'
        slot='sign-in'
        usernameAlias='email'
        hideSignUp={true}
        formFields={[
          {
            type: 'email',
            label: '',
            required: true,
          },
          {
            type: 'password',
            label: '',
            required: true,
          },
        ]}
      >
        <div slot='secondary-footer-content'>Contact admin for signup details.</div>
      </AmplifySignIn>
    </AmplifyAuthenticator>
  );
};

ReactDOM.render(<AppShell />, document.getElementById('root'));
