import React from 'react';
import Image from 'react-bootstrap/Image';
import styles from './Preview.module.scss';
import phoneFrame from './phoneFrame.svg';
import placeholderImg from './placeholderImg.svg';
import copy from './copy.svg';
import externalLink from './externalLink.svg';

const OfferPreview = ({
  brand,
  header,
  body,
  cta,
  referralUrl,
  discountCode,
  disclaimer,
  imageUrl,
  imgBlob = new Blob(),
}) => {
  let PreviewImg;
  if (imgBlob.size > 0) {
    PreviewImg = <Image src={URL.createObjectURL(imgBlob)} alt='' className={styles.image} />;
  } else if ((imageUrl && imgBlob.size === 0) || (imageUrl && !imgBlob)) {
    PreviewImg = <Image src={imageUrl} alt='' className={styles.image} />;
  } else {
    PreviewImg = <Image className={styles.placeholderImg} src={placeholderImg} alt='' />;
  }

  return (
    <div className={styles.componentContainer}>
      <div>
        <Image src={phoneFrame} alt='' className={styles.phoneFrame} fluid />
        <div className={styles.container}>
          {PreviewImg}
          <div className={styles.textContainer}>
            {!(
              brand ||
              header ||
              body ||
              cta ||
              referralUrl ||
              discountCode ||
              disclaimer
            ) && (
              <div>
                <p className={styles.brand}>Best Brand Ever</p>
                <p className={styles.header}>Offer header comes here</p>
                <p className={styles.body}>Offer text comes here...</p>
                <hr className={styles.divider} />
                <p className={styles.disclaimer}>Disclaimer comes here...</p>
                <div className={` ${styles.btnContainer} ${styles.btnCont1Extra}`}>
                  <div className={styles.discountCode}><Image src={copy} alt='' />XXXXXXX</div>
                  <div className={styles.btn}>
                    <Image src={externalLink} alt=''/>
                    <a
                      className={styles.btnText}
                      href={referralUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                        Click Me!
                    </a>
                  </div>
                </div>
              </div>
            )}
            {(brand ||
              header ||
              body ||
              cta ||
              referralUrl ||
              discountCode ||
              disclaimer) && (
              <>
                <p className={styles.brand}>{brand}</p>
                <p className={styles.header}>{header}</p>
                <p className={styles.body}>{body}</p>
                {disclaimer && (
                  <>
                    <hr className={styles.divider} />
                    <p className={styles.disclaimer}>{disclaimer}</p>
                  </>
                )}
                <div className={styles.btnContainer}>
                  {(discountCode || cta) && (
                    <div className={` ${styles.btnContainer} ${styles.btnCont2Extra}`}>
                      {discountCode && (
                        <div className={styles.discountCode}><Image src={copy} alt='' />{discountCode}</div>
                      )}
                      {cta && ( 
                        <div className={styles.btn}>
                          <Image src={externalLink} alt='' />
                          <a className={styles.btnText}>{cta}</a>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferPreview;
