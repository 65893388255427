import * as React from 'react';
import {Switch, BrowserRouter as Router} from 'react-router-dom';
import Routes from './routes';
import Navigation from './components/Navbar';

export default () => {
  return (
    <Router>
      <div>
        <Navigation />
        <Switch>{Routes}</Switch>
      </div>
    </Router>
  );
};
