import gql from 'graphql-tag';
import React from 'react';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AccountCircle from '@material-ui/icons/AccountCircle';

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  AccountCircle: forwardRef((props, ref) => (
    <AccountCircle {...props} ref={ref} />
  )),
};

export const gql_get_partners_for_list_component = gql`
  query GetPartnersForAdminList {
    getPartners {
      partners {
        id
        cognitoSub
        email
        companyID
        company
        size
        postcode
        city
        address
        contactName
        contactEmail
        contactPhone
        referral
        status
        service
      }
    }
  }
`;

export const referralSourceList = {
  1: 'Suomen Lukiolaisten Liitto SLL',
  2: 'Suomen Ammattiin Opiskelevien Liitto SAKKI',
  3: 'Suomen Yrittäjien paikallisyhdistys',
  4: 'LinkedIn',
  5: 'Mediatiedote',
  6: 'Tuttu / kollega',
  7: 'Muu',
};

export const gql_get_paid_offers_count = gql`
  query GetPaidOffersCount($partner: String!) {
    getPaidOffersCount(partner: $partner)
  }
`;

export const gql_get_paid_jobs_count = gql`
  query GetPaidJobsCount($partner: String!) {
    getPaidJobsCount(partner: $partner)
  }
`;

export const gql_get_total_jobs_count = gql`
  query GetTotalJobsCount($partner: String!) {
    getTotalJobsCount(partner: $partner) 
  }
`;

export const gql_get_total_offers_count = gql`
  query GetTotalOffersCount($partner: String!) {
    getTotalOffersCount(partner: $partner) 
  }
`;
