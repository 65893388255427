import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';

import {forwardRef} from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const qql_get_jobs_for_list_component = gql`
  query GetJobsForJobListComponent {
    getJobs {
      jobs {
        id
        title
        modified
        created
        status
      }
    }
  }
`;

export const qql_get_jobs_by_partner = gql`
  query GetJobsByPartner($partner: String) {
    getJobs(partner: $partner) {
      jobs {
        id
        title
        caption
        created
        description
        imageUrl
        applicationEmail
        applicationUrl
        keywords
        modified
        owner
        jobType
        workField
        contactPhone
        contactEmail
        dueDate
        status
        address
        city
        postalCode
      }
    }
  }
`;

export const gql_update_job_status = gql`
  mutation UpdateJobStatus($id: ID!, $status: JobStatus!) {
    updateJobStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const formatDates = (job) => {
  const dateFormat = 'D.M.YYYY HH:mm';
  return {
    ...job,
    modified: moment(job.modified).format(dateFormat),
    created: moment(job.created).format(dateFormat),
  };
};

export const formatStatus = (job) => {
  const statusMapper = (status) => {
    switch (status) {
      case 'PAID':
        return 'Paid, pending approval';
      case 'PAYMENTFAILED':
        return 'Payment failed';
      case 'PENDING':
        return 'Pending, not paid';
      case 'APPROVED':
        return 'Paid, approved';
      case 'REJECTED':
        return 'Paid, rejected';
      case 'PUBLISHED':
        return 'Paid, published';
      case 'UNPUBLISHED':
        return 'Paid, unpublished';
      case 'EXPIRED':
        return 'Paid, expired';
      // no default
    }
  };
  return {
    ...job,
    status_label: statusMapper(job.status),
  };
};

export const capitalizeHeader = (job) => {
  return {
    ...job,
    title: job.title.toUpperCase(),
  };
};

export const JobStatus = {
  PAID: 'PAID',
  PAYMENTFAILED: 'PAYMENTFAILED',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
  EXPIRED: 'EXPIRED',
};
