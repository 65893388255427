import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useQuery, useMutation} from '@apollo/client';
import {
  formatStatus,
  formatDates,
  capitalizeHeader,
  tableIcons,
  qql_get_offers_by_partner,
  gql_update_offer_status,
  gql_update_offer_status_and_dueDate,
  OfferStatus,
} from './helpers';
import {
  gql_get_paid_offers_count,
} from '../PartnerList/helpers';
import {pipe} from '../../utils';
import {
  BsXCircleFill,
  BsCheckCircle,
  BsFillPlayFill,
  BsFillPauseFill,
  BsCurrencyDollar,
  BsCalendarWeek,
} from 'react-icons/bs';
import { getNewDueDate } from './helpers';

const Loading = () => <p>Loading</p>;

const Error = ({error}) => <pre>{JSON.stringify(error, null, 2)}</pre>;

export default ({onRowClick, setSelectedOfferData, selectedPartner}) => {
  const [selectedOfferRow, setSelectedOfferRow] = useState(null);
  const [showPopup, setShowPopup] = useState({ visible: false });
  const [publicationDate, setPublicationDate] = useState(null);

  const handleClose = () => {
    setShowPopup({ visible: false });
  };
  
  const handleClick = () => {
    const { offerId, setSelectedOfferData, UpdateOfferStatusAndDueDate, newDueDate } = showPopup;
  
    UpdateOfferStatusAndDueDate({
      variables: {
        id: offerId,
        status: OfferStatus.PAID,
        dueDate: newDueDate,
      },
    });
    
    setSelectedOfferData((prevData) => {
      return {
        ...prevData,
        status: OfferStatus.PAID,
        dueDate: newDueDate,
      };
    });
  
    setShowPopup({ visible: false });
  };

  const formatOffer = pipe(formatStatus, formatDates, capitalizeHeader);

  if (!selectedPartner.id) {
    return null;
  }

  const {loading, error, data} = useQuery(qql_get_offers_by_partner, {
    variables: {partner: selectedPartner.id},
  });

  const formattedOffers = data?.getOffers.offers.map((offer) => {
    return formatOffer(offer);
  });

  const [updateOfferStatus] = useMutation(gql_update_offer_status, {
    refetchQueries: [
      {query: qql_get_offers_by_partner},
      {
        query: gql_get_paid_offers_count,
        variables: {
          partner: selectedPartner.id,
        },
      },
    ],
  });

  const [UpdateOfferStatusAndDueDate] = useMutation(gql_update_offer_status_and_dueDate, {
    refetchQueries: [
      {query: qql_get_offers_by_partner},
      {
        query: gql_get_paid_offers_count,
        variables: {
          partner: selectedPartner.id,
        },
      },
    ],
  });

  useEffect(() => {
    setSelectedOfferRow(null);
  }, [selectedPartner.id]);

  const reject = rejectAction(updateOfferStatus, setSelectedOfferData);
  const approve = approveAction(updateOfferStatus, setSelectedOfferData);
  const publish = publishAction(UpdateOfferStatusAndDueDate, setSelectedOfferData);
  const unpublish = unpublishAction(updateOfferStatus, setSelectedOfferData);
  const publishLater = publishLaterAction(UpdateOfferStatusAndDueDate, setSelectedOfferData, publicationDate);
  const makePaid2mon = makePaidAction('0.5', UpdateOfferStatusAndDueDate, setSelectedOfferData, setShowPopup, 2);
  const makePaid6mon = makePaidAction('0.75', UpdateOfferStatusAndDueDate, setSelectedOfferData, setShowPopup, 6);
  const makePaid12mon = makePaidAction('1', UpdateOfferStatusAndDueDate, setSelectedOfferData, setShowPopup, 12);

  return (
    <>
      {error && <Error error={error} />}

      {loading && <Loading />}

      {showPopup.visible && (
        <div>
          <div style={{
            background: 'white',
            padding: 20,
            borderRadius: 5,
            width: '80%',
            maxWidth: 500,
          }}>
            <h2>Are you sure?</h2>
            <p>{`Are you certain you want to mark this as PAID with a due date of ${showPopup.newDueDate} ?`}</p>
            <button style={{marginTop: 10}} onClick={handleClose}>No</button>
            <button style={{marginTop: 10}} onClick={handleClick}>Yes</button>
          </div>
        </div>
      )}

      {(
        <div>
          {/* Date input field */}
          <input type="date" value={publicationDate || ''} onChange={(event) => setPublicationDate(event.target.value)} />
        </div>
      )}
      
      {formattedOffers && (
        <MaterialTable
          columns={[
            {title: 'Created', field: 'created_formatted', defaultSort: 'asc'},
            {title: 'Modified', field: 'modified_formatted'},
            {title: 'Header', field: 'header'},
            {title: 'Status', field: 'status_label'},
          ]}
          data={formattedOffers}
          title='Offers'
          icons={tableIcons}
          onRowClick={(event, rowData) => {
            setSelectedOfferRow(rowData.tableData.id);
            onRowClick(rowData);
          }}
          options={{
            actionsColumnIndex: -1,
            padding: 'dense',
            sorting: true,
            rowStyle: (rowData) => ({
              fontSize: '0.8rem',
              backgroundColor:
                selectedOfferRow === rowData.tableData.id ? '#EEE' : '#FFF',
            }),
          }}
          actions={[
            reject,
            makePaid2mon,
            makePaid6mon,
            makePaid12mon,
            approve,
            publish,
            publishLater,
            unpublish,
          ]}
        />
      )}
    </>
  );
};

const publishAction = (UpdateOfferStatusAndDueDate, setSelectedOfferData) => (rowData) => {
  const validStatuses = [OfferStatus.APPROVED, OfferStatus.UNPUBLISHED, OfferStatus.PUBLISHEDLATER];
  const isStatusValid = validStatuses.includes(rowData.status);

  return {
    icon: () => (
      <BsFillPlayFill
        size='1.5rem'
        style={{color: isStatusValid ? 'limegreen' : 'gray'}}
      />
    ),
    tooltip: 'Clicking this button will publish the offer',
    onClick: () => {
      const newDueDate = getNewDueDate(rowData.created, rowData.dueDate);
      UpdateOfferStatusAndDueDate({
        variables: {
          id: rowData.id,
          status: OfferStatus.PUBLISHED,
          dueDate: newDueDate,
        },
      });
      setSelectedOfferData({
        ...rowData,
        status: OfferStatus.PUBLISHED,
        dueDate: newDueDate,
      });
    },
    disabled: !isStatusValid,
  };
};

const publishLaterAction = (UpdateOfferStatusAndDueDate, setSelectedOfferData, publicationDate) => (rowData) => {
  const validStatuses = [OfferStatus.APPROVED, OfferStatus.UNPUBLISHED, OfferStatus.PUBLISHEDLATER];
  const isStatusValid = validStatuses.includes(rowData.status);

  return {
    icon: () => (
      <BsCalendarWeek
        size='1.5rem'
        style={{color: isStatusValid && publicationDate ? 'limegreen' : 'gray'}}
      />
    ),
    tooltip: 'Clicking this button will publish the offer on the specific publication date',
    onClick: () => {
      const newDueDate = getNewDueDate(rowData.created, rowData.dueDate, publicationDate);
      const publicationDateISO = new Date(publicationDate).toISOString();
      UpdateOfferStatusAndDueDate({
        variables: {
          id: rowData.id,
          status: OfferStatus.PUBLISHEDLATER,
          dueDate: newDueDate,
          futurePublicationDate: publicationDateISO,
        },
      });
      setSelectedOfferData({
        ...rowData,
        status: OfferStatus.PUBLISHEDLATER,
        dueDate: newDueDate,
        futurePublicationDate: publicationDateISO,
      });
    },
    disabled: !isStatusValid || !publicationDate,
  };
};

const unpublishAction = (updateOfferStatus, setSelectedOfferData) => (rowData) => {
  const validStatuses = [OfferStatus.PUBLISHED];
  const isStatusValid = validStatuses.includes(rowData.status);

  return {
    icon: () => (
      <BsFillPauseFill
        size='1.5rem'
        style={{color: isStatusValid ? 'red' : 'gray'}}
      />
    ),
    tooltip: 'Clicking this button will unpublish this offer',
    onClick: () => {
      updateOfferStatus({
        variables: {
          id: rowData.id,
          status: OfferStatus.UNPUBLISHED,
        },
      });
      setSelectedOfferData({
        ...rowData,
        status: OfferStatus.UNPUBLISHED,
      });
    },
    disabled: !isStatusValid,
  };
};

const rejectAction = (updateOfferStatus, setSelectedOfferData) => (rowData) => {
  const validStatuses = [OfferStatus.APPROVED, OfferStatus.UNPUBLISHED, OfferStatus.PENDING, OfferStatus.PAID];
  const isStatusValid = validStatuses.includes(rowData.status);

  return {
    icon: () => (
      <BsXCircleFill
        size='1.5rem'
        style={{color: isStatusValid ? 'red' : 'grey', margin: '0rem 0.3rem'}}
      />
    ),
    tooltip: 'Reject',
    onClick: () => {
      updateOfferStatus({
        variables: {
          id: rowData.id,
          status: OfferStatus.REJECTED,
        },
      });
      setSelectedOfferData({
        ...rowData,
        status: OfferStatus.REJECTED,
      });
    },
    disabled: !isStatusValid,
  };
};

const approveAction = (updateOfferStatus, setSelectedOfferData) => (rowData) => {
  const validStatuses = [OfferStatus.PAID, OfferStatus.REJECTED];
  const isStatusValid = validStatuses.includes(rowData.status);

  return {
    icon: () => (
      <BsCheckCircle
        size='1.5rem'
        style={{color: isStatusValid ? 'limegreen' : 'grey', margin: '0rem 0.3rem'}}
      />
    ),
    tooltip: 'Approve',
    onClick: () => {
      updateOfferStatus({
        variables: {
          id: rowData.id,
          status: OfferStatus.APPROVED,
        },
      });
      setSelectedOfferData({
        ...rowData,
        status: OfferStatus.APPROVED,
      });
    },
    disabled: !isStatusValid,
  };
};

const makePaidAction = (colorOpacity, UpdateOfferStatusAndDueDate, setSelectedOfferData, setShowPopup, duration) => (rowData) => {
  const validStatuses = [OfferStatus.PENDING];
  const isStatusValid = validStatuses.includes(rowData.status);
  const newDueDate = new Date();
  newDueDate.setMonth(newDueDate.getMonth() + duration);
  return {
    icon: () => (
      <BsCurrencyDollar
        size='1.5rem'
        style={{color: isStatusValid ? `rgba(0, 153, 51, ${colorOpacity})` : 'grey', margin: '0rem 0.3rem'}}
      />
    ),
    tooltip: 'Make paid with ' + duration + ' months offer duration',
    onClick: () => {
      setShowPopup({
        visible: true,
        offerId: rowData.id,
        setSelectedOfferData,
        UpdateOfferStatusAndDueDate,
        newDueDate,
      });
    },
    disabled: !isStatusValid,
  };
};