import React, {useState} from 'react';
import PartnerList from '../PartnerList';
import JobList from '../JobList';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import JobPreview from '../Preview/JobPreview';
import styles from './main.module.scss';
import moment from 'moment';

export default ({partnerData}) => {
  const [selectedJobData, setSelectedJobData] = useState({});
  const {
    id,
    title,
    caption,
    description,
    applicationEmail,
    applicationUrl,
    imageUrl,
    workField,
    jobType,
    contactEmail,
    contactPhone,
    keywords,
    address,
    city,
    postalCode,
    status,
    dueDate,
  } = selectedJobData;

  const [currentPartnerId, setcurrentPartnerId] = useState('');
  const [hasSelectedJobData, setHasSelectedJobData] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState('');

  // only render partners with role 'jobs' in the partner table on the jobs side
  const formattedData = partnerData?.getPartners.partners.filter(partner => partner.service === 'jobs').map((partner) => {
    return {...partner};
  });

  const formatStatus = (status) => {
    return status[0].toUpperCase() + status.slice(1).toLowerCase();
  };

  const renderPartnerData = (partner) => {
    return (
      <Container className={styles.col} fluid>
        <p>
          <span className={styles.labels}>Company Name:</span>{' '}
          {partner.company ? partner.company : ''}
        </p>
        <p>
          <span className={styles.labels}>Company ID:</span>{' '}
          {partner.companyID ? partner.companyID : ''}
        </p>
        <p>
          <span className={styles.labels}>Contact Person:</span>{' '}
          {partner.contactName ? partner.contactName : ''}
        </p>
        <p>
          <span className={styles.labels}>Contact Email:</span>{' '}
          {partner.contactEmail ? partner.contactEmail : ''}
        </p>
        <p>
          <span className={styles.labels}>Contact Phone:</span>{' '}
          {partner.contactPhone ? partner.contactPhone : ''}
        </p>
        <p>
          <span className={styles.labels}>Billing Address:</span>{' '}
          {partner.address ? partner.address : ''}
        </p>
        <p>
          <span className={styles.labels}>City:</span>{' '}
          {partner.city ? partner.city : ''}
        </p>
        <p>
          <span className={styles.labels}>Postal Code:</span>{' '}
          {partner.postcode ? partner.postcode : ''}
        </p>
        <p>
          <span className={styles.labels}>Company Size:</span>{' '}
          {partner.size ? partner.size : ''}
        </p>
        <p>
          <span className={styles.labels}>Status:</span>{' '}
          {partner.status ? formatStatus(partner.status) : ''}
        </p>
      </Container>
    );
  };

  return (
    <div>
      <Container fluid>
        <Row className='justify-content-md-center'>
          {formattedData && (
            <Col sm='5'>
              <PartnerList
                formattedData={formattedData}
                onRowClick={(_event, rowData) => {
                  const {id} = rowData;
                  setcurrentPartnerId(id);
                  setHasSelectedJobData(false);
                  setSelectedPartner(rowData);
                }}
                role={'jobs'}
              />
            </Col>
          )}
          <Col sm='7'>
            {currentPartnerId && (
              <JobList
                currentPartnerId={currentPartnerId}
                setSelectedJobData={setSelectedJobData}
                selectedPartner={selectedPartner}
                onRowClick={(jobData) => {
                  setSelectedJobData(jobData);
                  setHasSelectedJobData(true);
                }}
              />
            )}
          </Col>
        </Row>
        <Row className={styles.bottomRow}>
          <Col>
            {currentPartnerId &&
              renderPartnerData(
                formattedData.find((partner) => partner.id === currentPartnerId)
              )}
          </Col>
          <Col>
            {hasSelectedJobData && (
              <Container className={styles.col} fluid>
                <p>
                  <span className={styles.labels}>Job ID: </span>
                  {id ? id : ''}
                </p>
                <p>
                  <span className={styles.labels}>Status: </span>
                  {status ? formatStatus(status) : ''}
                </p>
                <p>
                  <span className={styles.labels}>Title: </span>
                  {title ? title : ''}
                </p>
                <p>
                  <span className={styles.labels}>Caption: </span>
                  {caption ? caption : ''}
                </p>
                <p>
                  <span className={styles.labels}>Description: </span>
                  {description ? description : ''}
                </p>
                <p>
                  <span className={styles.labels}>Image URL: </span>
                  {imageUrl ? imageUrl : ''}
                </p>
                <p>
                  <span className={styles.labels}>Application Email: </span>
                  {applicationEmail ? applicationEmail : ''}
                </p>
                <p>
                  <span className={styles.labels}>Application Url: </span>
                  {applicationUrl ? applicationUrl : ''}
                </p>
                <p>
                  <span className={styles.labels}>Field of Work: </span>
                  {workField ? workField : ''}
                </p>
                <p>
                  <span className={styles.labels}>Type of Job: </span>
                  {jobType ? <ul>{jobType.map((job, index) => <li key={index}>{job}</li>)}</ul> : ''}
                </p>
                <p>
                  <span className={styles.labels}>Contact Email: </span>
                  {contactEmail ? contactEmail : ''}
                </p>
                <p>
                  <span className={styles.labels}>Contact Phone: </span>
                  {contactPhone ? contactPhone : ''}
                </p>
                <p>
                  <span className={styles.labels}>Due Date: </span>
                  {dueDate ? moment(dueDate).format('D.M.YYYY') : ''}
                </p>
                <p>
                  <span className={styles.labels}>Keywords: </span>
                  {keywords ? keywords : ''}
                </p>
                <p>
                  <span className={styles.labels}>Address: </span>
                  {address ? address : ''}
                </p>
                <p>
                  <span className={styles.labels}>City: </span>
                  {city ? city : ''}
                </p>
                <p>
                  <span className={styles.labels}>Postal Code: </span>
                  {postalCode ? postalCode : ''}
                </p>
              </Container>
            )}
          </Col>
          <Col sm='4' style={{maxWidth: '337px', minWidth: '337px'}}>
            {hasSelectedJobData && <JobPreview {...selectedJobData} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};