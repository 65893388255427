/*
    General purpose pipe function, takes N number of ops
    
    example: 
    const myPipe = pipe(myFunc1, myFunc2, myFunc3)
*/

export const pipe = (...ops) => {
  const pipeSection = (a, b) => (arg) => b(a(arg));
  return ops.reduce((prevOp, nextOp) => {
    return pipeSection(prevOp,nextOp);
  });
};