import React from 'react';
import Image from 'react-bootstrap/Image';
import styles from './Preview.module.scss';
import phoneFrame from './phoneFrame.svg';
import placeholderImg from './placeholderImg.svg';
import externalLink from './externalLink.svg';

const JobPreview = ({
  title,
  caption,
  description,
  applicationUrl,
  applicationEmail,
  workField,
  jobType,
  contactEmail,
  contactPhone,
  imgBlob = new Blob(),
  imageUrl,
}) => {
  let PreviewImg;

  if (imgBlob.size > 0) {
    PreviewImg = <Image className={styles.image} src={URL.createObjectURL(imgBlob)} alt='' />;
  } else if ((imageUrl && imgBlob.size === 0) || (imageUrl && !imgBlob)) {
    PreviewImg = <Image className={styles.image} src={imageUrl} alt='' />;
  } else {
    PreviewImg = <Image className={styles.placeholderImg} src={placeholderImg} alt='' />;
  }

  return (
    <div className={styles.componentContainer}>
      <div>
        <Image src={phoneFrame} alt='' className={styles.phoneFrame} fluid />
        <div className={styles.container}>
          {PreviewImg}
          <div className={styles.textContainer}>
            {!(
              title ||
              caption ||
              description ||
              applicationUrl ||
              applicationEmail ||
              workField ||
              jobType?.length ||
              contactEmail ||
              contactPhone
            ) && (
              <>
                <p className={styles.brand}>Title comes here...</p>
                <p className={styles.header}>Caption comes here...</p>
                <p className={styles.body}>Description here...</p>
                <p className={styles.body}>Field of work here...</p>
                <p className={styles.body}>Type of job here...</p>
                <p className={styles.body}>Application email or URL here...</p>
                <p className={styles.body}>Contact details here...</p>
                <div className={` ${styles.btnContainer} ${styles.btnCont1Extra}`}>
                  <div className={styles.btn}>
                    <Image src={externalLink} alt=''/>
                    <a
                      className={styles.btnText}
                      href={applicationUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      call to action here...
                    </a>
                  </div>
                </div>
              </>
            )}
            {(title ||
              caption ||
              description ||
              applicationUrl ||
              applicationEmail ||
              workField ||
              jobType?.length ||
              contactEmail ||
              contactPhone) && (
              <>
                <p className={styles.brand}>{title}</p>
                <p className={styles.header}>{caption}</p>
                <p className={styles.body}>{description}</p>
                {workField && (
                  <p className={styles.body}>{`Field of Work: ${workField}`}</p>
                )}
                {jobType?.length && (
                  <>
                    <p className={styles.body}>Type of Job:</p>
                    <ul className={styles.body}>
                      {jobType.map((type) => (
                        <li key={type}>{type}</li>
                      ))}
                    </ul>
                  </>
                )}
                {applicationEmail && (
                  <p
                    className={styles.body}
                  >{`Application email: ${applicationEmail}`}</p>
                )}
                {(contactPhone || contactEmail) && (
                  <>
                    <p className={styles.body}>Contact Details:</p>
                    <p className={styles.body}>{contactPhone}</p>
                    <p className={styles.body}>{contactEmail}</p>
                  </>
                )}
                {applicationUrl && (
                  <div className={` ${styles.btnContainer} ${styles.btnCont2Extra}`}>
                    <div className={styles.btn}>
                      <Image src={externalLink} alt=''/>
                      <a
                        className={styles.btnText}
                        href={`${applicationUrl}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Apply now
                      </a>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPreview;
